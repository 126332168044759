<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <v-container>
            <validation-observer ref="observer">
              <v-row>
                <v-col cols="12" sm="4">
                  <data-selector
                    v-if="selectedItem.type === 'truck'"
                    key="truckSelector"
                    v-model="item.truckId"
                    label="Truck#"
                    database-module="trucks"
                    getter="filtered"
                    item-text="id"
                    item-value="id"
                    :always-reload="true"
                    :filter-params="filterParams"
                  />
                  <data-selector
                    v-else
                    key="trailerSelector"
                    v-model="item.trailerId"
                    label="Trailer#"
                    database-module="trailers"
                    getter="filtered"
                    item-text="id"
                    item-value="id"
                    :always-reload="true"
                    :filter-params="filterParams"
                  />
                </v-col>
                <v-col cols="12" sm="8">
                  <data-selector
                    v-if="driverParams"
                    key="driverSelector"
                    v-model="item.driverId"
                    label="Driver"
                    database-module="drivers"
                    getter="filtered"
                    item-text="fullName"
                    item-value="id"
                    :always-reload="true"
                    :filter-params="driverParams"
                    :initialize="true"
                    :disabled="selectedItem.status === 'dropped'"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="Note" rules="required">
                    <v-textarea
                      v-model.trim="item.note"
                      auto-grow
                      label="Note"
                      rows="4"
                      row-height="30"
                      outlined
                      :disabled="!item.driverId"
                      :error-messages="errors"
                    >
                    </v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <date-picker :date.sync="item.date" label="Date" :disabled="!item.driverId" :not-empty="true" />
                </v-col>
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="error" @click="exit"> Cancel </v-btn>
          <v-btn color="success" text :disabled="!item.driverId" @click="onSubmit"> Save changes </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EditHeader from './_CommonEditHeader.vue'
// import DataSelector from './_DataSelector.vue'
import DataSelector from '../MDataSelector.vue'
import DatePicker from './_DatePicker.vue'

import { required } from 'vee-validate/dist/rules'
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    EditHeader,
    DataSelector,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
  },
  props: ['visible', 'selectedItem', 'dialogTitle', 'databaseModule'],
  data() {
    return {
      item: {
        truckId: '',
        trailerId: '',
      },
      driverParams: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeEdit', this.selectedItem)
        }
      },
    },
    filterParams() {
      return { assign: this.selectedItem.status }
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateInputs()
      }
    },
    'item.trailerId': {
      handler(val) {
        if (val) {
          this.updateDriverParams()
        }
      },
    },
    'item.truckId': {
      handler(val) {
        if (val) {
          this.updateDriverParams()
        }
      },
    },
  },
  methods: {
    submit() {
      var item = Object.assign({}, this.item)
      delete item.action
      if (item.date) {
        item.date = new Date(item.date).toISOString()
      }
      // console.log(item)
      var action = ''
      if (this.selectedItem.action === 'edit') {
        action = this.databaseModule + '/updateItem'
      } else if (this.selectedItem.action === 'add') {
        action = this.databaseModule + '/addItem'
      } else {
        throw 'Unexpected action in edit dialog'
      }

      this.$store
        .dispatch(action, item)
        .then(() => {
          //action is unchanged (add, edit)
          this.dialog = false
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
        .finally(() => {
          this.driverParams = false
        })
    },
    exit() {
      this.selectedItem.action = 'exit'
      this.driverParams = false
      this.dialog = false
    },
    updateInputs() {
      this.item = this.selectedItem

      /* ne zove edit 
      if (this.selectedItem.action === 'edit') {
        //this.id = this.selectedItem.id
        //this.type = this.contactType.find((elem) => elem.value === this.selectedItem.type)
        //this.contactInfo = this.selectedItem.contactInfo
      } else {
        this.item.truckId = ''
      }
      */
      requestAnimationFrame(() => {
        this.$refs.observer?.reset()
      })
    },
    updateDriverParams() {
      let post = this.selectedItem.status === 'picked' ? '' : 'Id'
      let type = this.selectedItem.type
      let val = this.selectedItem.status === 'picked' ? '0' : this.item[type + post]
      let obj = {}
      obj[type + post] = val
      this.driverParams = Object.assign({}, obj)
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) this.submit()
      })
    },
  },
}
</script>
