import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c('edit-header',{attrs:{"dialog-title":_vm.dialogTitle}}),_c(VCardText,[_c(VContainer,[_c('validation-observer',{ref:"observer"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[(_vm.selectedItem.type === 'truck')?_c('data-selector',{key:"truckSelector",attrs:{"label":"Truck#","database-module":"trucks","getter":"filtered","item-text":"id","item-value":"id","always-reload":true,"filter-params":_vm.filterParams},model:{value:(_vm.item.truckId),callback:function ($$v) {_vm.$set(_vm.item, "truckId", $$v)},expression:"item.truckId"}}):_c('data-selector',{key:"trailerSelector",attrs:{"label":"Trailer#","database-module":"trailers","getter":"filtered","item-text":"id","item-value":"id","always-reload":true,"filter-params":_vm.filterParams},model:{value:(_vm.item.trailerId),callback:function ($$v) {_vm.$set(_vm.item, "trailerId", $$v)},expression:"item.trailerId"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[(_vm.driverParams)?_c('data-selector',{key:"driverSelector",attrs:{"label":"Driver","database-module":"drivers","getter":"filtered","item-text":"fullName","item-value":"id","always-reload":true,"filter-params":_vm.driverParams,"initialize":true,"disabled":_vm.selectedItem.status === 'dropped'},model:{value:(_vm.item.driverId),callback:function ($$v) {_vm.$set(_vm.item, "driverId", $$v)},expression:"item.driverId"}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextarea,{attrs:{"auto-grow":"","label":"Note","rows":"4","row-height":"30","outlined":"","disabled":!_vm.item.driverId,"error-messages":errors},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('date-picker',{attrs:{"date":_vm.item.date,"label":"Date","disabled":!_vm.item.driverId,"not-empty":true},on:{"update:date":function($event){return _vm.$set(_vm.item, "date", $event)}}})],1)],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":_vm.exit}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","text":"","disabled":!_vm.item.driverId},on:{"click":_vm.onSubmit}},[_vm._v(" Save changes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }